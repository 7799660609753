import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { element } from 'prop-types';

// dashboard page 0 routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard/Default')));
const MomentsPage = Loadable(lazy(() => import('views/moments/Default')))
const CommunityPage = Loadable(lazy(() => import('views/communities/Default')));
const CommunityProfilePage = Loadable(lazy(() => import('views/communities/profile')))
const SetupPage = Loadable(lazy(() => import('views/setup/setup')));
const DeepDivePage = Loadable(lazy(() => import('views/deepdive/Default')));
const Organisation = Loadable(lazy(() => import('views/organisation')))
const DefaultError = Loadable(lazy(() =>  import('views/error/pages/default')))

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <CommunityPage />
    },
    {
      path: '/communities',
      element: <CommunityPage />
    },
    {
      path: '/community/profile',
      element: <CommunityProfilePage/>
    },
    {
      path: '/setup',
      element: <SetupPage />
    },
    {
      path: 'dashboard',
      element: <DashboardPage />
    },
    {
      path: 'deepdive',
      element: <DeepDivePage />
    },
    {
      path: '/error',
      element: <DefaultError/>
    },
    {
      path: '/organisation',
      element: <Organisation/>
    }
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-typography',
    //       element: <UtilsTypography />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-color',
    //       element: <UtilsColor />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-shadow',
    //       element: <UtilsShadow />
    //     }
    //   ]
    // },
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'tabler-icons',
    //       element: <UtilsTablerIcons />
    //     }
    //   ]
    // },
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'material-icons',
    //       element: <UtilsMaterialIcons />
    //     }
    //   ]
    // }
  ]
};

export default MainRoutes;
