import axios from 'axios';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
import { useSelector } from 'react-redux';
import supabase from './auth';

const cache = {};
const MAX_RETRIES = 3;  // Maximum number of retries
const RETRY_INTERVAL = 5000; // Retry interval in milliseconds (5 seconds)
import { getCurrentProject, getSession } from './projects';

export const ready_check = async () => { 
  let base_url = process.env.REACT_APP_ANALYTICS_API_ADDRESS
  let url = base_url + '/ready'

  const headers = {
    'x-api-key': process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json' // Or the correct Content-Type
  };

  let retries = 0;
  while (retries < MAX_RETRIES) {
    try {
      let res = await axios.get(url, { headers });

      // Added check for projects in sessionStorage

      if (res.data.ready) { // Check the 'ready' flag and if projects is not null
        console.log('Ready check successful');
        return true; // Or return res.data.ready directly
      } else {
        console.warn(`API not ready yet (attempt ${retries + 1}). Retrying...`);
      }
    } catch (error) {
      console.error(`Ready check failed (attempt ${retries + 1}):`, error);
    }
    retries++;
    if (retries < MAX_RETRIES) { // Wait before retrying, unless it's the last attempt
      await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL));
    }
  }

  console.error('Ready check failed after multiple attempts.');
  return false; 
}


export const post_request = async (url, body) => {
  const session = getSession()
  const currentProject = JSON.parse(sessionStorage.getItem('currentProject'));
    
    let token = session ? session.access_token : null; // Get token if user exists
    let projectId = currentProject ? currentProject.id : null; // Get projectId if it exists
  
    let base_url = process.env.REACT_APP_ANALYTICS_API_ADDRESS
    url = base_url + url
    let res = await axios.post(
        url,
        body,
        {
            headers: {
                'x-api-key':  process.env.REACT_APP_API_KEY,
                'Token':  token,
                'project': projectId
            }
        }
    )

    return res.data;
}

export const put_request = async (url, body) => {
  const session = getSession()
  const currentProject = getCurrentProject();
  let token = session ? session.access_token : null; // Get token if user exists
  let projectId = currentProject ? currentProject.id : null; // Get projectId if it exists

  let base_url = process.env.REACT_APP_ANALYTICS_API_ADDRESS
  url = base_url + url
  let res = await axios.put(
      url,
      body,
      {
          headers: {
              'x-api-key':  process.env.REACT_APP_API_KEY,
              'Token':  token,
              'project': projectId
          }
      }
  )

  return res.data;
}



export function removeFromCache(url, body = {},) {
  const currentProject = JSON.parse(localStorage.getItem('currentProject'));
  const cacheKey = `${url + currentProject.id}?${JSON.stringify(body)}`;
  if (cache[cacheKey]) {
    delete cache[cacheKey];
    console.log(`Removed ${cacheKey} from cache`);
    return true; // Indicate successful removal
  } else {
      console.log(`Cache key ${cacheKey} not found`);
      return false; // Indicate that the key was not found
  }
}

export const get_request = async (url, body = {}) => { // Set default value for body
    const currentProject = JSON.parse(sessionStorage.getItem('currentProject'));
    const cacheKey = `${url + currentProject?.id}?${JSON.stringify(body)}`;

    const cachedItem = cache[cacheKey];

    if (cachedItem && Date.now() - cachedItem.timestamp < 10 * 60 * 1000) {
        return cachedItem.response; // Return cached response
    }
  
    const session = getSession()
    let token = session ? session.access_token : null;
    let projectId = currentProject ? currentProject.id : null;
    let base_url = process.env.REACT_APP_ANALYTICS_API_ADDRESS;
    url = base_url + url;
  
    // Construct headers object
    const headers = {
        'x-api-key': process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json' // Or the correct Content-Type
    };
    if (token) {
      headers['Token'] = token;
    }
    
  if (projectId) {
      headers['Project'] = projectId;
  }
    let res = await axios.get(url, {
      params: {
        platforms: body.platforms,
        ...body
      },
      headers: headers
    });
  
    cache[cacheKey] = {
      response: res.data,
      timestamp: Date.now()
    };
    return res.data;
  }
