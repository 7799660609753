// src/components/ErrorBoundary.js
import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here if needed
    console.error("Error caught in Error Boundary: ", error, errorInfo);
    window.location.pathname = '/error'
  }

  render() {
    if (this.state.hasError) {
      // Redirect to the 404 page when an error occurs
      // return <Navigate to="/error" />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
