// assets
import {IconDashboard, IconHomeCheck} from '@tabler/icons';

// constant
const icons = { IconDashboard, IconHomeCheck };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const setup = {
    id: 'setup',
    title: 'Setup',
    type: 'group',
    children: [
        { 
            id: 'organisation',
            title: 'Organisation',
            type: 'item',
            url: '/organisation',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        { 
            id: 'platforms',
            title: 'Platforms',
            type: 'item',
            url: '/setup',
            icon: icons.IconHomeCheck,
            breadcrumbs: false
        },
    ]
}
  
  export default setup;
  