import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// routing
import Routes from 'routes';
// defaultTheme
import themes from 'themes';

import { setProject, setProjects, getCurrentProject, getSession, getProjects } from 'utils/projects';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import supabase from 'utils/auth';
import ErrorBoundary from 'views/error/ErrorBoundary';

// ==============================|| APP ||============================== //
const App = () => {
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectsGatedPages, setProjectsGatedPages] = useState(['setup','dashboard','deepdive', 'communities', 'organisation'])

  useEffect(() => {
    setLoading(true);
    const currentPath = window.location.pathname.split('/')[1]
    const currentProject = getCurrentProject()
    let session = getSession()

    if ((!currentPath || projectsGatedPages.includes(currentPath)) && !session) {
      navigate('/login');
    }

    else if (projectsGatedPages.includes(currentPath) && !currentProject) {
        navigate('/communities');
    }

    supabase.auth.onAuthStateChange((event, session) => {
      try {
        if (event === 'SIGNED_IN') {
          sessionStorage.setItem('session', JSON.stringify(session))

          if (!(getProjects())) {
            setProjects()
          }
        }
        if (event === 'SIGNED_OUT') {
          sessionStorage.removeItem('session')
          sessionStorage.removeItem('projects')
          sessionStorage.removeItem('currentProject')

          // Redirect to login page
          navigate('/login');
        }

        if (event === 'INITIAL_SESSION') {
          sessionStorage.setItem('session', JSON.stringify(session))
          if (!getProjects()) {
            setProjects()
          }
        }
      } catch (err) {
        setError(err);
        console.error('Auth state change error:', err);
      } 
    })

    setLoading(false);

  }, []);

  // Optional: Loading state component
  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  // Optional: Error handling
  if (error) {
    return <Typography color="error">An error occurred: {error.message}</Typography>;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <ErrorBoundary>
            {loading ? (
              <Typography>Loading...</Typography>
            ) : (
                <>
                  <Routes />
                </>
            )}
          </ErrorBoundary>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;