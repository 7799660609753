import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Stack } from '@mui/material';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import PlatformTimeFilter from '../../../ui-component/filter/PlatformTimeFilter';
import ProfileSection from './ProfileSection';
import { IconMenu2 } from '@tabler/icons';

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  const currentUrl = window.location.pathname;
  const filterUrls = ['dashboard', 'deepdive']


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 2,
        [theme.breakpoints.down('sm')]: {
          gap: 1
        }
      }}
    >
      {/* Logo & Toggler Section */}
      <Box
        sx={{
          minWidth: 228,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            minWidth: 'auto'
          }
        }}
      >
        <Box 
          component="span" 
          sx={{ 
            display: { xs: 'none', md: 'block' }, 
            flexGrow: 1 
          }}
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.primary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* Flexible Space */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Right Side Elements */}
      <Stack 
        direction="row" 
        spacing={{ xs: 1, sm: 2 }}
        alignItems="center"
        sx={{
          marginLeft: 'auto',
          width: 'auto',
          [theme.breakpoints.down('md')]: {
            maxWidth: '60%'
          }
        }}
      >
        <Box 
          sx={{
            flexShrink: 1,
            minWidth: 0, // Allows the box to shrink below its content size
            [theme.breakpoints.down('sm')]: {
              maxWidth: '150px' // Adjust this value based on your needs
            }
          }}
        >
          {filterUrls.some(url => currentUrl.includes(url)) &&          
            <PlatformTimeFilter />          
          }
          
        </Box>
        <ProfileSection />
      </Stack>
    </Box>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;