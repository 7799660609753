import supabase from "./auth";
import { get_request } from "./request";


export const setProject = (projectId) => {
    const project = JSON.parse(sessionStorage.getItem('projects')).find((project) => project.id == projectId)
    sessionStorage.setItem('currentProject', JSON.stringify(project))
};

export const setSession = (session) => {
    sessionStorage.setItem('session', JSON.stringify(session))
};

export const getCurrentProject = () => {
    const project = sessionStorage.getItem('currentProject')
    if (project && project != 'undefined') {
        return JSON.parse(project)
    }
    return null
};


export const getSession = () => {
    const session = sessionStorage.getItem('session')
    if (session && session != 'undefined' && session != 'null') {
        return JSON.parse(session)
    } else {
        supabase.auth.getSession().then((({ data: { session } }) => {
            sessionStorage.setItem('session', JSON.stringify(session))
            return session
        }))
    }
    return null
};

export const getProjects = () => {
    const projects = JSON.parse(sessionStorage.getItem('projects'))
    if (projects && projects != 'undefined') {
            return projects
        }
    return null
};



export const setProjects = async () => {
    try {
        let session = getSession()
        if (session) {
            let auth_id = session.user.id
            const projects = await get_request('/user/projects', {
                auth_id: auth_id
            })
            if (projects.data.length > 0) {
                sessionStorage.setItem('projects', JSON.stringify(projects.data))
                sessionStorage.setItem('currentProject', JSON.stringify(projects.data[0]))
                return true;
            } else {
                throw Error('no projects')
            }
        } else {
            throw Error('no user')
        }
    } catch (err){
        console.log(err)
    }
}

